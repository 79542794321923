.profile {
    background-color: whitesmoke;
    border-radius: 6px;
    width: 100%;
    display: inline-block;
}

.profile_name {
    line-height: 18px;
    padding: 15px;
    padding-right: 100px;

}

.profile_name .name {
    text-transform: uppercase ;
    font-size: 17px;
    font-weight: bold;

}
.profile_name .title {
    font-size: 13px;
    color: hex#777;

}

.profile_image {
    margin-top: -10px;
    -webkit-clip-path: polygon(0 9%, 100% 0, 100% 94%, 0% 100%) !important;
    clip-path: polygon(0 9%, 100% 0, 100% 94%, 0% 100%) !important;
}

.profile_image img {
    width: 100%;
    
}

/*information*/
.profile_information {
    top: 0;
    left: 0; 
    margin: -20px 10px 30px; 
}

.profile_information .timeline_content {
    margin-top: 2px; 
    margin-bottom: 2px !important; 
}
.timelineItem_text.css-ahj2mt-MuiTypography-root {
    font-size: 15px !important;
    color: #787878;
    font-weight: 400; 
    text-decoration: none; 
}
.timelineItem_text > a {
    font-size: 17px; 
    color: blue;
    text-decoration: none; 
}
.timelineItem_text > span {
    font-size: 17px; 
    color: black;
}

.button_container {
    width: 100%;
    display: flex !important;
    justify-content: center; 
    margin-top: 10px; 

}

.css-1oa1nt :before {
    display: none; 
}
.css-1rcbby2 :before{
    display: none; 
}