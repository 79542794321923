/* Tabs */
.custom_tabs .customTabs_item {
    padding: 0px;
    min-width: 0px;
    margin-right: 20px;
    text-transform: inherit;
    font-size: 14px;
    font-weight: 400;
    outline-width: 0px;
  }
  
  /* Use .custom_tabs .Mui-selected instead of .custom_tabs .active */
  .custom_tabs .Mui-selected {
    color: var(--main-color) !important;
  }

/* Cards */
.customCard{
    height: 100%;
    max-width: 350px;
    box-shadow: 0px 0px 15px 0px rgba(4,6,4,0.98);
    border-radius: 5px;
    overflow: hidden;
    outline-width: 0px !important;
}
.customCard .customCard_image{
    height: 140px; 

}
.customCard .customCard_title{
    font-weight: 500;
}

.customCard .customCard_caption{
    color: gray;
    font-weight: 400;

}
/* Dialog */

.projectDialog .projectDialog_image{
    max-height: 300px;
    width: 100%;
    object-fit: cover;
    object-position: center center;
}
.projectDialog .projectDialog_description{
    margin-top: 10px !important;
}
.projectDialog .projectDialog_actions{
    justify-content: center !important;
    margin-bottom: 10px;
}
.projectDialog .projectDialog_icon{
    color: darkslategray;
    margin: 0px 12px;
    cursor: pointer;
}
.projectDialog .projectDialog_icon :hover {
 color: var(--main-color);
}
.projectDialog .projectDialog_icon > .MuiSvgIcon-root{
    font-size: 30px !important;
}