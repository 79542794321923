/* About me section */
.aboutme_text{
    color: darkslategray;
    font: smaller;
    line-height: 20px; 
    white-space: pre-line;
}


/* timeline section */
.timeline_title{
    font-size: 16px !important;
    font-weight:500;
}
.timeline_date{
    color: darkslategray;
}
.timeline_description{
    color: gray;
    font-size:small;
    margin-top: 6px; 
}


/* Services */
.service {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    text-align: center;
  }
.service_title {
    font-size: 16px;
    font-weight: 500;
    line-height: 35px;
  }
.service_description {
    color: #989898;
    margin-top: 5px;
  }
.service_icon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    border-radius: 20%;
    background-color: var(--main-color);
    width: 45px !important;
    height: 45px !important;
  }

.service_icon > .MuiSvgIcon-root {
    font-size: 36px !important;
    color: darkslategray;

    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
}
.css-1jgtvd5 {
    display: flex;
}

/* Skills */
.skill{
    border-radius: 8px; 
    padding: 15px; 
    box-shadow: 0px 0px 48px 0px rgba(4,6,4,0.08);
    min-height: 186px;
    height: 100%;
}

.skills_title{
    font-size: 14px;
    font-weight: 500;
    line-height: 35px; 
}
.skill_description{
    display: flex;
    align-items: center;
    color: #989898;
    font-weight: 400;
    line-height: 18px; 

}
.dot_description{
    margin: 5px;
}
.graybg{
    background-color: #fafafa;

}

/* Contacts */
.contactInfo_item {
    font-size: 14px; 
    color: #787878;

}
.contactInfo_item > span {
    color: black;
    font-weight: 500;

}
.contactInfo_socialsContainer {
    margin-top: 25px;
}
.contactInfo_social > a > .MuiSvgIcon-root {
    font-size: 25px;
    margin-right: 10px;
    color: black;
}