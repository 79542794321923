.custom_btn {
  background-color: var(--main-color) !important; 
  font-size: 14px; 
  font-weight: 500;
  border: 0;
  border-radius: 50px; 
  padding: 1px 12px; 
  line-height: 35px;
  min-width: 100px; 
  outline: none; 

}

.btn_icon_container {
    color: #000; 
    background: #fff;
    border-radius: 50px; 
    height: 27px; 
    width: 27px; 
    line-height: 29px;
    text-align: center; 
    display: flex;
    align-items: center;
    justify-content: center; 

}
.btn_icon_container > .MuiSvgIcon-root{
    font-size: 16px; 
}

.btn_text{
  font-size: 14px; 
  text-transform: none; 
  text-align: center; 
  width: 100%; 
  margin-right: 5px; 
  margin-left: 5px; 

}